import { Injectable } from "@angular/core";
import {
  Http,
  Response,
  Headers,
  RequestOptions,
  JSONPBackend,
  ResponseContentType,
} from "@angular/http";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";
import { LoginService } from "../login/login.service";
import { AppService } from "../app.service";
import swal from "sweetalert2";
import { OnBoardService } from "app/on-board/on-board.service";
import { PortFolioService } from "app/portFolio/portFolio.service";
import { ReassignPortfolioComponent } from "app/reassign-portfolio/reassign-portfolio.component";
import { HttpClient } from "@angular/common/http";
import { saveAs } from "file-saver/src/FileSaver";

@Injectable({
  providedIn: "root",
})
export class LoansService {
  public access_token: any;
  public pathName: any;
  public access_token_asw: any;
  public profileBaseUrl: any;
  public loanUserSummary: any;
  public;
  public bulkLoanUserSummary = [];
  public ascOrder = new Map();
  profileDetails: any;
  approveSummary: any;
  public contractId: any;
  public savingStatus: boolean = true;
  public selectedProduct: any;
  public computeSchedule: any = [];
  public ContractStatus: any;
  public totalcontract: any;
  public profileMap = new Map();
  public profileMap2 = new Map();
  public profileMap3 = new Map();
  public rootuserpaymentMap = new Map();
  public profilepaymentMap = new Map();
  public maps = new Map();
  public eventBody: any;
  map = new Map();
  map2 = new Map();
  map3 = new Map();
  public accountName: any;
  public contractType: any;
  loanUserSummarySchedule: any;
  Units: any;
  loanProfile = {};
  private headers = new Headers({ "content-type": "application/json" });
  private options = new RequestOptions({ headers: this.headers });
  constructor(
    private _http: Http,
    private httpClient: HttpClient,
    private loginService: LoginService,
    private commonservice: AppService,
    private onBoardService: OnBoardService,
    private portfolioService: PortFolioService
  ) {
    this.access_token = loginService.access_tokenMethod();
    this.access_token_asw = loginService.accessTokenAsw();
  }

  profilePort() {
    return (this.profileBaseUrl =
      this.loginService.urlNumber() +
      ":" +
      this.loginService.portnumber() +
      "/ProfileService");

    //  return this.profileBaseUrl="http://188.214.128.73:"+this.loginService.portnumber()+"/mff/api";
    //   return this.profileBaseUrl="http://188.214.128.73:6000/mff/api";
    // return this.profileBaseUrl="http://localhost:"+this.loginService.portnumber()+"/mff/api";
    //   return this.profileBaseUrl = "http://localhost:6061/mff/api";
    /// return this.profileBaseUrl="http://localhost:5151/MFFService";
  }

  profilePort1() {
    return (this.profileBaseUrl =
      this.loginService.urlNumber() +
      ":" +
      this.loginService.portnumber() +
      "/WorkflowService");

    //  return this.profileBaseUrl="http://188.214.128.73:"+this.loginService.portnumber()+"/mff/api";
    //   return this.profileBaseUrl="http://188.214.128.73:6000/mff/api";
    // return this.profileBaseUrl="http://localhost:"+this.loginService.portnumber()+"/mff/api";
    //   return this.profileBaseUrl = "http://localhost:6061/mff/api";
    /// return this.profileBaseUrl="http://localhost:5151/MFFService";
  }

  getAllApprovedProfiles() {
    return this._http
      .get(
        this.profilePort() +
        "/approvalProfiles?access_token=" +
        this.access_token,
        this.options
      )
      .map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  generateDraft(productId, contractId, profileId, fileName, type) {
    return this._http
      .post(
        this.profilePort() +
        "/upload/productTemplate/" +
        profileId +
        "/" +
        type +
        "?access_token=" +
        this.access_token +
        "&productId=" +
        productId +
        "&contractId=" +
        contractId +
        "&fileName=" +
        fileName,
        this.options
      )
      .map((response: Response) => response.json());
  }

  // createEnvelope(payload){
  //     return this._http.post(this.commonservice.eSignUrl+'/envelope?recipientEmail='+payload.recipientEmail+'&recipientName='+payload.recipientName+'&recipientId='+payload.recipientId+"&fileName="+payload.fileName+'&filePath='+payload.filePath,this.options).map((res:Response)=>res.json()).catch(this.errorHandler)
  // }
  createEnvelope(payload) {
    return this._http
      .post(
        this.commonservice.eSignUrl +
        "/esign/envelope" +
        "?access_token=" +
        this.access_token,
        payload
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  checkEnvelopeStatus(envelopeId) {
    return this._http
      .get(
        this.commonservice.eSignUrl +
        "/esign/envelopeStatus?envelopeId=" +
        envelopeId +
        "&access_token=" +
        this.access_token,
        this.options
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  getPaymentLink(payload) {
    return this._http
      .post(
        this.commonservice.thirdpartyurl +
        "/paymentLink?access_token=" +
        this.access_token,
        payload,
        this.options
      )
      .map((response: Response) => response.json());
  }

  getStripePaymentLink(payload) {
    return this._http
      .post(
        this.commonservice.thirdpartyurl +
        "/stripe/paymentLink?access_token=" +
        this.access_token,
        payload,
        this.options
      )
      .map((response: Response) => response.json());
  }

  sendCustomTwilioMsg(payload) {
    return this._http
      .post(
        this.commonservice.thirdpartyurl +
        "/twilio/sendMessage?access_token=" +
        this.access_token,
        payload,
        this.options
      )
      .map((response: Response) => response.json());
  }

  getEnvelope_Document_ID(profileId, contractId) {
    return this._http
      .get(
        this.commonservice.eSignUrl +
        "/esign/envelopeIds?profileId=" +
        profileId +
        "&contractId=" +
        contractId +
        "&access_token=" +
        this.access_token,
        this.options
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  downloadEsignDocument(
    envelopeId,
    documentId,
    fileName,
    profileId,
    contractId
  ) {
    const header = { Accept: "application/octet-stream" };
    let headers = new Headers({ Accept: "application/octet-stream" });
    let options = new RequestOptions({ headers: headers });
    return this.httpClient
      .get(
        this.commonservice.eSignUrl +
        "/esign/envelope/document?envelopeId=" +
        envelopeId +
        "&documentId=" +
        documentId +
        "&fileName=" +
        fileName +
        "&profileId=" +
        profileId +
        "&contractId=" +
        contractId +
        "&access_token=" +
        this.access_token,
        { responseType: "arraybuffer" }
      )
      .map((report) => {
        return report;
      });
  }

  getGeneratedDraft(profileId, type, contractid) {
    return this._http
      .get(
        this.profilePort() +
        "/files/" +
        profileId +
        "/" +
        type +
        "?access_token=" +
        this.access_token +
        "&contractUUID=" +
        contractid
      )
      .map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  // getAllApprovedProfiles() {
  //   return this._http.get(this.profilePort() + '/approvedProfiles?access_token=' + this.access_token, this.options).map((response: Response) => response.json())
  //     .catch(this.errorHandler);
  // }
  // getproductsByWorkflowID(workFlowID) {
  //   return this._http.get(this.profilePort() + '/workFlow/products/' + workFlowID + '?access_token=' + this.access_token, this.options).map((response: Response) => response.json())
  //     .catch(this.errorHandler);
  // }
  createContract(loansDetails) {
    return this._http
      .post(
        this.profilePort() + "/loans?access_token=" + this.access_token,
        loansDetails,
        this.options
      )
      .map((response: Response) => response.json())
      .catch(this.errorHandler);
  }
  createProduct(productDetails) {
    return this._http
      .post(
        this.loginService.PRODUCT_URL +
        "/products?access_token=" +
        this.access_token,
        productDetails
      )
      .map((res: Response) => res.json());
  }
  getContractDetails() {
    return this._http
      .get(
        this.profilePort() +
        "/workFlow/products/?access_token=" +
        this.access_token,
        this.options
      )
      .map((response: Response) => response.json())
      .catch(this.errorHandler);
  }
  getContractByID(contractID) {
    //alert(this.commonservice.contractURL+'/contracts/?contractUUID='+contractID+'&access_token='+this.loginService.access_token);
    return this._http
      .get(
        this.loginService.CONTRACT_URL +
        "/contracts?ContractUUID=" +
        contractID +
        "&access_token=" +
        this.access_token,
        this.options
      )
      .map((res: Response) => res.json()["data"])
      .catch(this.errorHandler);
  }

  getContractByIDMFF(contractID) {
    //alert(this.commonservice.contractURL+'/contracts/?contractUUID='+contractID+'&access_token='+this.loginService.access_token);
    return this._http
      .get(
        this.profilePort() +
        "/getcontractsBycontractUUID/?contractUUID=" +
        contractID +
        "&access_token=" +
        this.access_token,
        this.options
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  getPackageDetails() {
    return this._http
      .get(
        this.commonservice.portMFC +
        "/mfc/GetPackage/?access_token=" +
        this.access_token,
        this.options
      )
      .map((response: Response) => response.json())
      .catch(this.errorHandler);
  }
  getPackageDetailsByID(productID) {
    return this._http
      .get(
        this.commonservice.portMFC +
        "/mfc/GetPackage/" +
        productID +
        "?access_token=" +
        this.access_token,
        this.options
      )
      .map((response: Response) => response.json())
      .catch(this.errorHandler);
  }
  getBranches() {
    return this._http
      .get(
        this.commonservice.portMFC +
        "/mfc/branches/?access_token=" +
        this.access_token,
        this.options
      )
      .map((response: Response) => response.json())
      .catch(this.errorHandler);
  }
  getProvincesDetails() {
    return this._http
      .get(
        this.commonservice.portMFC +
        "/mfc/GetProvinces/?access_token=" +
        this.access_token,
        this.options
      )
      .map((response: Response) => response.json())
      .catch(this.errorHandler);
  }
  getContractTerms(contractID) {
    return this._http
      .post(
        this.loginService.CASPER_URL +
        "/contract/update?contractuuid=" +
        contractID +
        "&access_token=" +
        this.access_token,
        this.options
      )
      .map((res: Response) => res.json()["data"])
      .catch(this.errorHandler);
  }
  getCityByDistrictId(districtID) {
    return this._http
      .get(
        this.commonservice.portMFC +
        "/mfc/City/" +
        districtID +
        "?access_token=" +
        this.access_token,
        this.options
      )
      .map((response: Response) => response.json())
      .catch(this.errorHandler);
  }
  getDistrictsByProvinceId(provinceid) {
    return this._http
      .get(
        this.commonservice.portMFC +
        "/mfc/Districts/" +
        provinceid +
        "?access_token=" +
        this.access_token,
        this.options
      )
      .map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  getEconomicActivitiesDetails() {
    return this._http
      .get(
        this.commonservice.portMFC +
        "/mfc/GetEconomicActivities/?access_token=" +
        this.access_token,
        this.options
      )
      .map((response: Response) => response.json())
      .catch(this.errorHandler);
  }
  approveProfiles(selectedProfiles) {
    return this._http
      .put(
        this.profilePort() +
        "/editApprovalProfiles?access_token=" +
        this.access_token,
        selectedProfiles
      )
      .map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  // create loans

  getProducts() {
    return this._http
      .get(
        this.loginService.PRODUCT_URL +
        "/products?productID=&access_token=" +
        this.access_token
      )
      .map((res: Response) => res.json());
  }

  getProduct(productID) {
    return this._http
      .get(
        this.loginService.PRODUCT_URL +
        `/products?productID=` +
        productID +
        "&access_token=" +
        this.access_token
      )
      .map((res: Response) => res.json()["data"]);
  }
  editProduct(productID, productData) {
    return this._http
      .put(
        this.loginService.PRODUCT_URL +
        "/products/" +
        productID +
        "?access_token=" +
        this.access_token,
        productData
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }
  productStatus(productID, status) {
    return this._http
      .put(
        this.loginService.PRODUCT_URL +
        "/products/" +
        productID +
        "/status/" +
        status +
        "?access_token=" +
        this.access_token,
        status
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  computeContract(dealDetails) {
    return this._http
      .post(
        this.loginService.contractURL +
        "/contracts/schedule/compute?access_token=" +
        this.access_token_asw,
        dealDetails
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  computeContract1(contractUUID) {
    return this._http
      .get(
        this.loginService.CONTRACT_URL +
        "/contracts/compute?access_token=" +
        this.access_token +
        "&contractUUID=" +
        contractUUID
      )
      .map((res: Response) => res.json()["data"])
      .catch(this.errorHandler);
  }

  provideAccountByUserId(id) {
    return this._http
      .get(
        this.loginService.PAYMENT_URL +
        "/payments/channel/users/" +
        id +
        "?access_token=" +
        this.access_token
      )
      .map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  getMarketObjectByRootUser() {
    return this._http
      .get(
        this.loginService.MARKET_OBJECT_URL +
        "/market/objects?access_token=" +
        this.loginService.access_token
      )
      .map((res: Response) => res.json()['data'])
      .catch(this.errorHandler);
  }

  provideAccountByChannelId(channelId) {
    return this._http
      .get(
        this.loginService.PAYMENT_URL +
        "/payments/channel/" +
        channelId +
        "?access_token=" +
        this.access_token
      )
      .map((response: Response) => response.json())
      .catch(this.errorHandler);
  }
  OauthDetails() {
    return this._http
      .get(
        this.loginService.PAYMENT_URL +
        "/oauthDetails?access_token=" +
        this.access_token
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }
  createLoan(loanDetails) {
    console.log("inService:" + JSON.stringify(loanDetails));
    //alert(JSON.stringify(dealDetails));
    // alert(this.commonservice.rootURLDeals+'/deals?access_token='+this.access_token);
    return this._http
      .post(
        this.loginService.CONTRACT_URL +
        "/contracts?access_token=" +
        this.access_token,
        loanDetails
      )
      .map((res: Response) => res.json()["data"])
      .catch(this.errorHandler);
  }

  updateContractFlag(profileID, flag) {
    return this._http
      .put(
        this.profilePort1() +
        "/profile/" +
        profileID +
        "/contractflag/" +
        flag +
        "?access_token=" +
        this.access_token,
        this.options
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  getAllContracts(contractStatus) {
    return this._http
      .get(
        this.loginService.contractURL +
        "/contracts?ContractStatus=" +
        contractStatus +
        "&access_token=" +
        this.access_token_asw
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  // executeLoan(cUID){
  //   return this._http.put(this.loginService.contractURL+'/contracts/'+cUID+'/execute?access_token='+this.access_token_asw, this.options)
  //   .map((res:Response) => res.json()).catch(this.errorHandler);
  // }
  executeLoan(loanDetails) {
    return this._http
      .put(
        this.loginService.CONTRACT_URL +
        "/contracts/execute?access_token=" +
        this.access_token,
        loanDetails
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }
  provideProfile() {
    //alert("getting profiles : " +this.commonservice.ProfileUrl + '/profiles?access_token='+this.access_token);
    return this._http
      .get(
        this.loginService.ProfileUrl +
        "/profiles?access_token=" +
        this.access_token_asw,
        this.options
      )
      .map((response: Response) => response.json())
      .catch((error) => <any>error);
  }

  updatedealStatus(_ContractUUID) {
    return this._http
      .post(
        this.loginService.CONTRACT_URL +
        "/contracts/execute?access_token=" +
        this.access_token +
        "&contractUUID=" +
        _ContractUUID,
        ""
      )
      .map((res: Response) => res.json());
  }

  // updatedealStatus1(_ContractUUID) {
  //   return this._http.get(this.loginService.contractURL + '/contracts/execute?access_token=' + this.access_token_asw + '&contractUUID=' + _ContractUUID)
  //     .map((res: Response) => res.json());

  // }

  provideProfileById(ProfileID) {
    return this._http
      .get(
        this.loginService.PROFILE_URL +
        "/profiles/" +
        ProfileID +
        "?access_token=" +
        this.access_token,
        this.options
      )
      .map((response: Response) => response.json())
      .catch(this.errorHandler);
  }
  provideAccountMethodId(id) {
    return this._http
      .get(
        this.loginService.PAYMENT_URL +
        "/payments/channel/" +
        id +
        "?access_token=" +
        this.access_token,
        this.options
      )
      .map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  checkContract(id) {
    return this._http
      .get(
        this.loginService.contractURL +
        "/contracts?LegalEntityIDCounterparty=" +
        id +
        "&access_token=" +
        this.access_token_asw
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }
  getContracts() {
    return this._http
      .get(
        this.loginService.contractURL +
        "/contracts?access_token=" +
        this.access_token_asw
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }
  // getContractsByID(profileID){
  //   return this._http.get(this.loginService.contractURL+'/contracts?LegalEntityIDCounterparty='+profileID+'&access_token='+this.access_token_asw)
  // .map((res:Response) => res.json()).catch(this.errorHandler);
  // }

  getContractsByID(profileID) {
    return this._http
      .get(
        this.loginService.CONTRACT_URL +
        "/contracts?profileId=" +
        profileID +
        "&access_token=" +
        this.access_token
      )
      .map((res: Response) => res.json()["data"])
      .catch(this.errorHandler);
  }
  getContractsByuuid(contractUUID) {
    return this._http
      .get(
        this.loginService.CONTRACT_URL +
        "/contracts?&access_token=" +
        this.access_token+"&contractUUID="+contractUUID
      )
      .map((res: Response) => res.json()["data"])
      .catch(this.errorHandler);
  }

  getContractsEventsByID(profileID, eventType) {
    return this._http
      .get(
        this.loginService.BUSINESS_EVENT_URL +
        "/contracts/events?profileId=" +
        profileID +
        "&eventType=" +
        eventType +
        "&access_token=" +
        this.access_token
      )
      .map((res: Response) => res.json()["data"])
      .catch(this.errorHandler);
  }

  updateContract(dealDetails) {
    return this._http
      .put(
        this.loginService.CONTRACT_URL +
        "/contracts?access_token=" +
        this.access_token,
        dealDetails
      )
      .map((res: Response) => res.json()["data"])
      .catch(this.errorHandler);
  }
  rescheduleContract(contractID, contractData) {
    return this._http
      .post(
        this.loginService.CONTRACT_URL +
        "/contracts/updateContract/" +
        contractID +
        "?access_token=" +
        this.access_token,
        contractData
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  nominalInterestRate(contractID, contractData) {
    return this._http
      .post(
        this.loginService.CONTRACT_URL +
        "/contracts/updateContract/" +
        contractID +
        "?access_token=" +
        this.access_token,
        contractData
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  updateDailySummary(contractID) {
    return this._http
      .post(
        this.commonservice.updateSummary +
        "/updateDailySummaryByContractuuid?access_token=" +
        this.access_token,
        contractID
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  validateRescheduleContract() {
    return this._http
      .post(
        this.commonservice.validateURL +
        "/rescheduleContract?access_token=" +
        this.access_token,
        this.options
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  validateMoratorium() {
    return this._http
      .post(
        this.commonservice.validateURL +
        "/moratorium?access_token=" +
        this.access_token,
        this.options
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }
  getEvents() {
    return this._http
      .get(
        this.commonservice.soltixURL +
        "5050/BusinessEventService/events?access_token=" +
        this.access_token_asw
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  getProcessedEvents(contracID) {
    return this._http
      .get(
        this.loginService.BUSINESS_EVENT_URL +
        "/transactionEvents?contractUUID=" +
        contracID +
        "&access_token=" +
        this.access_token
      )
      .map((res: Response) => res.json()["data"])
      .catch(this.errorHandler);
  }

  getEventsByDate1(status, from, to, eventType, noOfRecords, pageNumber) {
    return this._http
      .get(
        this.commonservice.soltixURL +
        "5050/BusinessEventService/events/from/" +
        from +
        "/to/" +
        to +
        "/type/" +
        eventType +
        "/status/" +
        status +
        "/" +
        pageNumber +
        "/" +
        noOfRecords +
        "?access_token=" +
        this.access_token_asw
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }
  penaltyInfo(contractID, fpData) {
    return this._http
      .post(
        this.loginService.CONTRACT_URL +
        "/contracts/new/event/" +
        contractID +
        "?access_token=" +
        this.access_token,
        fpData
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  addFee(contractID, fpData) {
    return this._http
      .post(
        this.loginService.CONTRACT_URL +
        "/contracts/new/event/" +
        contractID +
        "?access_token=" +
        this.access_token,
        fpData
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  validateAddFee() {
    return this._http
      .post(
        this.commonservice.validateURL +
        "/addFee?access_token=" +
        this.access_token,
        this.options
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }
  // getEventsByPagintion(status,from,to,eventType,noOfRecords,pageNumber){
  //   return this._http.get(this.commonservice.soltixURL+'5050/BusinessEventService/events/from/'+from+'/to/'+to+'/type/'+eventType+'/status/'+status+'/'+pageNumber+'/'+noOfRecords+'?access_token='+this.access_token_asw)
  // .map((res:Response) => res.json()).catch(this.errorHandler);
  // }

  getBulkReceive(from, to, noOfRecords, pageNumber) {
    return this._http
      .get(
        this.commonservice.soltixURL +
        "5050/BusinessEventService/bulkReceive/from/" +
        from +
        "/to/" +
        to +
        "/" +
        pageNumber +
        "/" +
        noOfRecords +
        "?access_token=" +
        this.access_token_asw
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }
  // getEventsByDate1(status,from,to,eventType){
  //   return this._http.get(this.commonservice.soltixURL+'5050/BusinessEventService/getevents/from/'+from+'/to/'+to+'?status='+status+'&eventType='+eventType+'&access_token='+this.access_token_asw)
  // .map((res:Response) => res.json()).catch(this.errorHandler);
  // }

  // getEventsByDate(from,to){
  //   return this._http.get(this.commonservice.soltixURL+'5050/BusinessEventService/getevents/from/'+from+'/to/'+to+'?access_token='+this.access_token_asw)
  // .map((res:Response) => res.json()).catch(this.errorHandler);
  // }
  getEventsByDate(from, to) {
    return this._http
      .get(
        this.commonservice.soltixURL +
        "5050/BusinessEventService/getevents/from/" +
        from +
        "/to/" +
        to +
        "?access_token=" +
        this.access_token_asw
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  getEventsByDates(from, to) {
    return this._http
      .get(
        this.loginService.BUSINESS_EVENT_URL +
        "/events/graphValuesDayWise/" +
        from +
        "/" +
        to +
        "?access_token=" +
        this.loginService.access_token
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  getEventsByTime(time) {
    return this._http
      .get(
        this.loginService.BUSINESS_EVENT_URL +
        "/liquidityReport?time=" +
        time +
        "&access_token=" +
        this.access_token
      )
      .map((res: Response) => res.json()['data'])
      .catch(this.errorHandler);
  }
  // getEventsByEventType(eventType,status,from,to){
  //   return this._http.get(this.commonservice.soltixURL+'5050/BusinessEventService/getevents/from/'+from+'/to/'+to+'?status='+status+'&eventType='+eventType+'&access_token='+this.access_token_asw)
  // .map((res:Response) => res.json()).catch(this.errorHandler);
  // }

  getEventsByEventType(eventType, status, from, to, noOfRecords, pageNumber) {
    return this._http
      .get(
        this.commonservice.soltixURL +
        "5050/BusinessEventService/events/from/" +
        from +
        "/to/" +
        to +
        "/type/" +
        eventType +
        "/status/" +
        status +
        "/" +
        pageNumber +
        "/" +
        noOfRecords +
        "?access_token=" +
        this.access_token_asw
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  searchEventsByLinkedProfileID(eventType, linkedProfileID, status, from, to) {
    return this._http
      .get(
        this.commonservice.soltixURL +
        "5050/BusinessEventService/events/profile/" +
        linkedProfileID +
        "/fromdate/" +
        from +
        "/to/" +
        to +
        "/type/" +
        eventType +
        "/status/" +
        status +
        "?access_token=" +
        this.access_token_asw
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }
  searchBulkReceiveBylinkedProfileID(linkedProfileID) {
    return this._http
      .get(
        this.commonservice.soltixURL +
        "5050/BusinessEventService/bulkReceive/" +
        linkedProfileID +
        "?access_token=" +
        this.access_token_asw
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  // getEventsbyType(branchId) {
  //   return this._http.get(this.loginService.BUSINESS_EVENT_URL + '/events?eventType=OPS' + (branchId ? '&branchId=' + branchId : '') + '&access_token=' + this.access_token)
  //     .map((res: Response) => res.json()).catch(this.errorHandler);
  // }

  getEventsbyType() {
    return this._http
      .get(
        this.loginService.BUSINESS_EVENT_URL +
        "/business/events?eventType=OPS&access_token=" +
        this.access_token
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  getEventsbyBranchId(branchId) {
    return this._http
      .get(
        this.loginService.BUSINESS_EVENT_URL +
        "/business/events?eventType=OPS&branchId=" +
        branchId +
        "&access_token=" +
        this.access_token
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  getEventsCustom(customFilter, value) {
    return this._http
      .get(
        this.commonservice.soltixURL +
        "5050/BusinessEventService/events?" +
        customFilter +
        "=" +
        value +
        "&access_token=" +
        this.access_token_asw
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  getEventbyID(contractID) {
    return this._http
      .get(
        this.loginService.BUSINESS_EVENT_URL +
        "/business/events?contractUUID=" +
        contractID +
        "&access_token=" +
        this.access_token
      )
      .toPromise()
      .then((res) => res.json())
      .catch(this.errorHandler);
  }

  getEventsbyID(contractID) {
    return this._http
      .get(
        this.loginService.BUSINESS_EVENT_URL +
        "/business/events?contractUUID=" +
        contractID +
        "&access_token=" +
        this.access_token
      )
      .map((res: Response) => res.json()["data"])
      .catch(this.errorHandler);
  }

  getSavingTxnHistory(contractID) {
    return this._http
      .get(
        `${this.loginService.PAYMENT_URL}/contracts/transaction/history/${contractID}`
      )
      .map((res: Response) => res.json()["data"]["transactionHistory"])
      .catch(this.errorHandler);
  }

  getEventsbyIDMFF(contractID, eventType) {
    return this._http
      .get(
        this.profilePort() +
        "/geteventsBycontractUUID?contractUUID=" +
        contractID +
        "&access_token=" +
        this.access_token +
        (eventType ? "&eventType=" + eventType : "&eventType=")
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  validateExceptionTopUp() {
    return this._http
      .get(
        this.commonservice.validateURL +
        "/loanException?access_token=" +
        this.access_token
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  getEventsbyEventType(contractID, type) {
    return this._http
      .get(
        this.loginService.BUSINESS_EVENT_URL +
        "/business/events?contractUUID=" +
        contractID +
        "&eventType=" +
        type +
        "&access_token=" +
        this.access_token
      )
      .map((res: Response) => res.json()["data"])
      .catch(this.errorHandler);
  }
  getEventsbyEventType1(contractID) {
    return this._http
      .get(
        this.loginService.BUSINESS_EVENT_URL +
        "/business/events?contractUUID=" +
        contractID +
        "&access_token=" +
        this.access_token
      )
      .map((res: Response) => res.json()["data"])
      .catch(this.errorHandler);
  }
  getTransactionByEventID(eventID) {
    return this._http
      .get(
        this.commonservice.validateURL +
        "/TransactionsService/txs?eventID=" +
        eventID +
        "&access_token=" +
        this.access_token
      )
      .map((res: Response) => res.json()["data"])
      .catch(this.errorHandler);
  }
  provideAccountByProfileId(id) {
    // alert(this.commonservice.paymentUrl + "/payments/method/profiles/"+id+'?access_token='+this.access_token);
    return this._http
      .get(
        this.loginService.PAYMENT_URL +
        "/payments/channel/profiles/" +
        id +
        "?access_token=" +
        this.access_token,
        this.options
      )
      .map((response: Response) => response.json()["data"])
      .catch(this.errorHandler);
  }
  provideSavingsByProfileId(id) {
    // alert(this.commonservice.paymentUrl + "/payments/method/profiles/"+id+'?access_token='+this.access_token);
    return this._http
      .get(
        this.loginService.contractURL +
        "/contracts/" +
        id +
        "/history?access_token=" +
        this.access_token_asw,
        this.options
      )
      .map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  makeTransactionASW(payment) {
    return this._http
      .post(
        this.loginService.TRANSACTION_URL +
        "/transaction?access_token=" +
        this.access_token,
        payment
      )
      .map((res: Response) => res.json()["data"])
      .catch(this.errorHandler);
  }

  // savePortfolioRepayment(repaymentPayLoad) {
  //   return this._http.post(this.portfolioService.portfolioport() + '/savePortfolioRepayment?access_token=' + this.access_token, repaymentPayLoad)
  //     .map((res: Response) => res.json()).catch(this.errorHandler);
  // }

  savePortfolioRepayment(repaymentPayLoad) {
    return this._http
      .post(
        this.portfolioService.portfolioport() +
        "/savePortfolioRepayment?access_token=" +
        this.access_token,
        repaymentPayLoad
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }
  makeSavingsPaymentASW(id, amt) {
    return this._http
      .put(
        this.loginService.paymentUrl +
        "/payments/channel/" +
        id +
        "/fund/" +
        amt +
        "?access_token=" +
        this.access_token_asw,
        this.options
      )
      .map((res: Response) => res.json());
  }

  prePayDelay(contractuuid, date) {
    return this._http
      .get(
        this.loginService.CONTRACT_URL +
        "/contracts/prepay/delay/" +
        contractuuid +
        "/" +
        date +
        "?access_token=" +
        this.access_token,
        this.options
      )
      .map((res: Response) => res.json());
  }

  validatePrePayDelay() {
    return this._http
      .get(
        this.commonservice.validateURL +
        "/prepay/delaypayments?access_token=" +
        this.access_token,
        this.options
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }
  // getDelinquency(date) {
  //   // alert(this.commonservice.paymentUrl + "/payments/method/profiles/"+id+'?access_token='+this.access_token);
  //  return this._http.get(this.loginService.contractURL + '/contracts/delinquency/report/'+date+'?access_token='+this.access_token_asw, this.options).map((response: Response) => response.json())
  //    .catch(this.errorHandler);
  //  }

  terminateContract(contractUUID, json) {
    return this._http
      .put(
        this.loginService.CONTRACT_URL +
        "/contracts/terminate/" +
        contractUUID +
        "?access_token=" +
        this.access_token,
        json
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  preSettlementTerminateContract(contractUUID, json) {
    return this._http
      .put(
        this.loginService.CONTRACT_URL +
        "/contracts/pre/maturity/" +
        contractUUID +
        "?access_token=" +
        this.access_token,
        json
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  validateTerminateContract() {
    return this._http
      .post(
        this.commonservice.validateURL +
        "/terminateContract?access_token=" +
        this.access_token,
        this.options
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  // getDelinquency(date,branch) {
  //   // alert(this.commonservice.paymentUrl + "/payments/method/profiles/"+id+'?access_token='+this.access_token);
  //  return this._http.get(this.loginService.contractURL + '/contracts/delinquency/report/'+date+'?'+(branch?'branchId='+branch+'&':'')+'access_token='+this.access_token_asw, this.options).map((response: Response) => response.json())
  //    .catch(this.errorHandler);
  //  }

  getDelinquency(date, branch) {
    // alert(this.commonservice.paymentUrl + "/payments/method/profiles/"+id+'?access_token='+this.access_token);
    return this._http
      .get(
        this.portfolioService.portfolioport() +
        "/contracts/delinquency/report/" +
        date +
        "?" +
        (branch ? "branchId=" + branch + "&" : "") +
        "access_token=" +
        this.access_token,
        this.options
      )
      .map((response: Response) => response.json()["data"]["portfolio"])
      .catch(this.errorHandler);
  }

  validateDelinquency() {
    // alert(this.commonservice.paymentUrl + "/payments/method/profiles/"+id+'?access_token='+this.access_token);
    return this._http
      .get(
        this.commonservice.validateURL +
        "/deliquencyTracker?access_token=" +
        this.access_token,
        this.options
      )
      .map((response: Response) => response.json())
      .catch(this.errorHandler);
  }
  //CHECKING USER PERMISSIONS
  validateSaveContract() {
    return this._http
      .post(
        this.commonservice.validateURL +
        "/saveContract?access_token=" +
        this.access_token,
        this.options
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }
  validateComputeContract() {
    return this._http
      .post(
        this.commonservice.validateURL +
        "/computeContract?access_token=" +
        this.access_token,
        this.options
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }
  validateEditContract() {
    return this._http
      .put(
        this.commonservice.validateURL +
        "/editContract?access_token=" +
        this.access_token,
        this.options
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }
  validateExecuteContract() {
    return this._http
      .put(
        this.commonservice.validateURL +
        "/executeContract?access_token=" +
        this.access_token,
        this.options
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }
  validateDisburseContract() {
    return this._http
      .post(
        this.commonservice.validateURL +
        "/disburseContract?access_token=" +
        this.access_token,
        this.options
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }
  validateRepayContract() {
    return this._http
      .post(
        this.commonservice.validateURL +
        "/repayContract?access_token=" +
        this.access_token,
        this.options
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  validateWaiveOfInterest() {
    return this._http
      .post(
        this.commonservice.validateURL +
        "/waiveOffInterest?access_token=" +
        this.access_token,
        this.options
      )
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  errorHandler(error) {
    let errorMessage = JSON.parse(error._body);

    if (errorMessage.status !== 200) {
      swal({
        title: `<h3 class="swal-msg-font swal-title-font">Sorry!</h3>`,
        html: `<h5 class="swal-msg-font swal-text-font">${errorMessage.message}</h5>`,
        showCloseButton: true,
        showConfirmButton: false,
        showCancelButton: false,
        focusConfirm: true,
        type: "warning",
      });
    } else if (
      errorMessage.message == "Access is Denied" ||
      errorMessage.message == "Access is denied"
    ) {
      swal({
        title: `<h3 class="swal-msg-font swal-title-font">Sorry!</h3>`,
        html: `<h5 class="swal-msg-font swal-text-font">${errorMessage.message}</h5>`,
        showCloseButton: true,
        showConfirmButton: false,
        showCancelButton: false,
        focusConfirm: true,
        type: "warning",
      });
    }

    return Observable.throw(error || " server error ");
  }
}
